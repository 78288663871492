@mixin on-tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin on-desktop {
  @media (min-width: 1060px) {
    @content;
  }
}

.header {
  width: 100%;
  height: 630px;
  background-color: rgb(50, 39, 46);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @include on-desktop {
    height: 100vh;
  }
}

.top {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: rgb(30, 23, 27);
  padding-inline: 16px;
  box-sizing: border-box;
  flex-shrink: 0;

  @include on-tablet {
    padding-inline: 25px;
    justify-content: space-between;
  }

  @include on-desktop {
    padding-inline: 50px;
  }
}

.logo {
  margin: 0;
  color: white;
  font-size: 23px;

  &_home {
    color: #ecbc42;
    font-weight: bold;
  }
}

.nav {
  display: none;

  @include on-tablet {
    display: flex;
  }

  &__item {
    text-decoration: none;
    color: white;
    margin-left: 30px;
    position: relative;
    transition: color 0.3s;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #ecbc42;

      transition: transform 0.3s;
      transform: scale(0);
      transform-origin: left;
    }

    &:hover {
      color: #ecbc42;
    }

    &:hover::before {
      transform: scale(1);
    }

    &_act {
      border: 2px solid #ecbc42;
      padding: 10px 15px;
      border-radius: 20px;
      margin-left: 0;

      &:before {
        display: none;
      }
    }

    @include on-desktop {
      margin-left: 50px;
    }
  }
}

.menu {
  position: fixed;
  right: 16px;
  top: 16px;
  z-index: 1;

  @include on-tablet {
    display: none;
  }
}

.content {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 30px;

  @include on-tablet {
    margin-top: 15px;
    flex-direction: row-reverse;
    justify-content: space-around;
  }
}

.infoe {
  display: flex;
  flex-direction: column;
  align-items: center; // Centers the children (like your button) horizontally
  justify-content: center; // Optional, if you also want vertical centering
  width: 100%; // Takes full width of its parent to center content correctly

  @include on-tablet {
    margin-right: 20px;
  }
}

.zagolovok {
  color: white;
  margin: 0;
  margin-inline: auto;
  text-align: center;
  font-size: 30px;
  width: 250px;
  line-height: 35px;

  @include on-tablet {
    font-size: 40px;
    width: 300px;
  }

  @include on-desktop {
    font-size: 70px;
    line-height: 70px;
    width: 500px;
  }
}

.info {
  color: white;
  margin: 0;
  margin-block: 15px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;

  @include on-tablet {
    text-align: center;
    margin-block: 20px;
    text-transform: uppercase;
  }

  @include on-desktop {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

.button {
  margin-block: 30px 40px;
  color: white;
  padding: 20px 40px;
  background-color: #ecbc42;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s;
  width: 180px;

  &:hover {
    background-color: #cea338;
  }

  @include on-desktop {
    padding: 25px 48px;
    font-size: 20px;
  }
}

.car {
  width: 90%;
  max-width: 318px;

  @include on-tablet {
    width: 45%;
    max-width: 600px;
  }
}

.scale {
  display: inline-block;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}
