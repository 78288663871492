@mixin on-tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin on-desktop {
  @media (min-width: 1060px) {
    @content;
  }
}

.etap {
  display: flex;
  flex-direction: column;
  background-image: url(../../../image/car/font-street.jpg);
  background-position: center;
  background-size: cover;
  padding: 50px 15px;
  color: white;

  &__container {
    background-color: rgba(0, 0, 0, 0.94);
    padding: 15px;
    max-width: 425px;
    margin-inline: auto;
  }

  &__title {
    text-align: center;
    font-size: 30px;
  }

  &_num {
    font-size: 20px;
    font-weight: bold;
  }

  &_number {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      display: block;
      left: -6px;
      top: 0;
      width: 100%;
      height: 3px;
      background-color: #ecbc42;
    }

    &::before {
      position: absolute;
      content: '';
      display: block;
      left: -7px;
      top: 0;
      width: 3px;
      height: 80%;
      background-color: #ecbc42;
    }
  }

  &__info {
    margin-bottom: 50px;
  }
}
